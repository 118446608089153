function initWidgetUrl() {
   let script = document.createElement("script");
   script.type = "text/javascript";
   script.id = "ze-snippet";
   script.async = true;
   script.src =
      "https://static.zdassets.com/ekr/snippet.js?key=5575733a-965b-40b6-b2fd-66557ca2e99a";
   script.onload = function () {
      zE("webWidget", "setLocale", "en");
      setHelpCenterSuggestions();
      changeStyle();
   };
   let parent = document.getElementsByTagName("script")[0];
   parent.parentNode.insertBefore(script, parent);
}

function setHelpCenterSuggestions() {
   if (zE?.setHelpCenterSuggestions) {
      zE.setHelpCenterSuggestions({ url: true });
   }
}

function changeStyle() {
   zE("webWidget:on", "open", function (event) {
      const topMargin = 40;
      const arrowMargin = 267;
      const academyIcon = document.querySelector(".academy span");
      const academyIconPositionInfo = academyIcon.getBoundingClientRect();
      const leftMargin =
         academyIconPositionInfo.left +
         academyIconPositionInfo.width / 2 -
         arrowMargin;
      let count = 10;
      const interval = setInterval(() => {
         if (count === 0) {
            clearInterval(interval);
         } else {
            count -= 1;
         }
         const webWidget = document.querySelector("#webWidget");

         if (webWidget) {
            const innerDoc = webWidget.contentDocument;
            const embed = innerDoc.querySelector("#Embed");
            const style = innerDoc.createElement("style");
            embed.appendChild(style);
            style.type = "text/css";
            style.appendChild(
               innerDoc.createTextNode(
                  `.arrow { 
                position: absolute;
                top: 9px; 
                right: 100px; 
                width: 0px; 
                height: 0px; 
                border-left: 7px solid transparent; 
                border-right: 7px solid transparent; 
                border-bottom: 7px solid #474C64;
             }`
               )
            );
            const container = innerDoc.querySelector(
               "#Embed div[data-embed='helpCenterForm'] div"
            );
            container.style.position = "relative";
            container.style.borderRadius = "4px";
            const arrow = innerDoc.createElement("div");
            container.before(arrow);
            arrow.classList.add("arrow");
            setWidgetOffset(topMargin, leftMargin);
            clearInterval(interval);
         }
      }, 300);
   });
}

function setWidgetOffset(vertical = 0, horizontal = 0) {
   Boolean(zE) &&
      zE("webWidget", "updateSettings", {
         webWidget: {
            offset: {
               vertical: `${vertical}px`,
               horizontal: `${horizontal}px`,
            },
         },
      });
}
